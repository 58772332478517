import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import "../styles/swiper.scss"
import "../styles/customerSwiper.scss"
import Img from "gatsby-image"
import styled from "styled-components"
import { DefaultOptionsQuery } from "../hooks/defaultOptions"


const StyledWrapper = styled.div`
    background-color: #EEEEEE;    
`

const StyledCustomerHeadline = styled.h4`
    margin-bottom: 70px;
    font-size: 40px;
    font-weight: normal;
    line-height: 60px;
`


const StyledContainer = styled.div`
    padding-bottom: 100px;
    padding-top: 100px;
`

const swiperparams = {
  slidesPerView: 2,
  autoplay: {
    delay: 5000,
  },
  breakpoints: {
    768: {
      slidesPerView: 3,
    },
    1024: {
      slidesPerView: 4,
    },
  },
  spaceBetween: 64,
}

export const KundenSliderComponent = () => {
  const defaultOptions = DefaultOptionsQuery()
  const data = useStaticQuery(graphql`
      query {
          allWpAcfKunde {
              nodes {
                  featuredImage {
                      node {
                          localFile {
                              childImageSharp {
                                  fluid(maxWidth: 60) {
                                      ...GatsbyImageSharpFluid
                                  }

                              }
                              extension
                              publicURL
                          }
                      }
                  }
              }
          }
      }
  `)

  return (
    <StyledWrapper>
      <StyledContainer className={"container"}>
        <div className={"row justify-content-center"}>
          <div className={"col-8 text-center"}>
            {defaultOptions.wp.startseite.acfThemeSettings.homepage_kunden_headline &&
            <StyledCustomerHeadline>
              {defaultOptions.wp.startseite.acfThemeSettings.homepage_kunden_headline}
            </StyledCustomerHeadline>
            }
          </div>
          <div className={"col-10 col-lg-6"}>
            <div className={"customer__swiper"}>
              <Swiper {...swiperparams}>
                {
                  data.allWpAcfKunde.nodes.map((item, index) => {
                      if (!item.featuredImage.node.localFile.childImageSharp && item.featuredImage.node.localFile.extension === "svg") {
                        return (
                          <SwiperSlide key={index}>

                            <img css={{
                              display: "block",
                              width: "100%",
                              maxHeight: "100px",
                            }} src={item.featuredImage.node.localFile.publicURL} alt={""}/>

                          </SwiperSlide>
                        )
                      }
                      return (
                        <SwiperSlide key={index}>
                          <Img css={{
                            display: "block",
                            width: "100%",
                          }} fluid={item.featuredImage.node.localFile.childImageSharp.fluid}/>

                        </SwiperSlide>
                      )
                    },
                  )
                }
              </Swiper>
            </div>
          </div>
        </div>
      </StyledContainer>
    </StyledWrapper>
  )
}
