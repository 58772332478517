import React from "react"
import styled from "styled-components"
import { device } from "../utils/devices"
import { AllWordpressWpTeamLarge } from "../hooks/allWordpressWpTeamLarge"
import Img from "gatsby-image"
import { Headline } from "./headline"

const StyledHero = styled.div`
    position: relative;
    margin-bottom: 100px;
    @media ${device.desktop}{
    margin-bottom: 50px;
    margin-top: 0;   
  }
    h1 {
      margin-bottom: 30px;
      margin-top: 100px;
      @media ${device.desktop}{ 
        margin-top: 0;
        margin-bottom: 0;
      }
    }
`
const StyledHeroTextWrapper = styled.div`
  @media ${device.desktop}{     
    left: 85px;
    position: absolute;
    bottom: 0;
    z-index: 2;
    transform: translateY(50%);
  }
`

const StyledHeroBgWrapper = styled.div`
    background: white;
`

const StyledHeroText = styled.div`
  @media ${device.desktop}{    
      position: relative;      
       &:after {
        content: '';
        z-index: -1;
        position: absolute;
        left: 10px;
        bottom: 10px;
        width: 100%;
        height: 100%;
        background: black;
      }
  }
`

export const Hero = ({headline, subline}) => {
  const allWordpressWpTeamLarge  = AllWordpressWpTeamLarge()
  return (
    <StyledHero className={"container"}>
      <StyledHeroTextWrapper className={"col-12 col-xl-7"}>
        <StyledHeroBgWrapper className={"row"}>
          <StyledHeroText className={"col-12 p-xl-3"}>
            <Headline headlineH1={headline} thinSubline={subline}/>
          </StyledHeroText>
        </StyledHeroBgWrapper>
      </StyledHeroTextWrapper>
      <div className={"row"}>
        {allWordpressWpTeamLarge.allWpAcfFotograf.nodes.map((item, index) => (
          <div key={index} className={"col p-0"}>
            {item.featuredImage.node.localFile &&
            <Img css={`filter: url("#${item.acfFotografenDetails.farbe}");`}
                 fluid={item.featuredImage.node.localFile.childImageSharp.fluid}/>
            }
          </div>
        ))}
      </div>
    </StyledHero>
  )
}
