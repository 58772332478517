import React from "react"
import { Link } from "gatsby"

export const Button = ({children, isLink, to}) => {


  if (isLink) {
    return (
      <Link className={"btn btn-outline-primary"} to={to}>
        {children}
      </Link>
    )
  }

  return (
    <button className={"btn btn-outline-primary"}>
      {children}
    </button>
  )
}
