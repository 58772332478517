import React from "react"
import styled from "styled-components"
import { Button } from "./button"

const StyledTaglineContainer = styled.div`
  margin-bottom: 150px;
  margin-top: 150px;
`

const StyledTagLineHashtag = styled.h4`
  font-weight: bold;
  font-size: 20px;
`

const StyledTagLineText = styled.h5`
  font-size: 40px;
  font-weight: normal;
  line-height: 60px;
`


export const TagLine = ({ hashtag, text, buttonText, buttonLink }) => {
  return (
    <StyledTaglineContainer className={"container"}>
      <div className={"row justify-content-center"}>
        <div className={"col-11 col-md-8 text-center"}>

          {hashtag &&
          <StyledTagLineHashtag>
            {hashtag}
          </StyledTagLineHashtag>
          }

          {text &&
          <StyledTagLineText>
            {text}
          </StyledTagLineText>
          }

          {buttonLink && buttonText &&
          <Button isLink={true} to={buttonLink}>
            {buttonText}
          </Button>
          }

        </div>
      </div>
    </StyledTaglineContainer>
  )
}
