import { useStaticQuery, graphql } from "gatsby"

export const AllWordpressWpTeamLarge = () => (
  useStaticQuery(
    graphql`
        query {
            allWpAcfFotograf(sort: {fields: menuOrder}) {
                nodes {
                    title
                    slug
                    link
                    acfFotografenDetails {
                        active
                        content
                        farbe
                        schwerpunkte
                        kurzbeschreibung
                    }
                    featuredImage {
                        node {
                            localFile {
                                childImageSharp {
                                    fluid(
                                        maxWidth: 360,
                                        quality: 80,
                                    ) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
  )
)

