import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Services } from "../components/services"
import { KundenSliderComponent } from "../components/customer"
import { TeamComponent } from "../components/team"
import { Contact } from "../components/contact"
import { Hero } from "../components/hero"
import { TagLine } from "../components/tagline"
import { ProjectSwiper } from "../components/projectSwiper"
import { DefaultOptionsQuery } from "../hooks/defaultOptions"

export default () => {

  const data = DefaultOptionsQuery()

  return (
    <Layout>
      <SEO title="Home"/>
      <Hero headline={data.wp.startseite.acfThemeSettings.homepageHeadline}
            subline={data.wp.startseite.acfThemeSettings.homepageSubline}/>
      <TagLine buttonLink={"/kontakt"}
               buttonText={data.wp.startseite.acfThemeSettings.homepageIntroButtonText}
               text={data.wp.startseite.acfThemeSettings.homepageIntro}
               hashtag={data.wp.startseite.acfThemeSettings.homepageHashtag}
      />
      <TeamComponent/>
      <ProjectSwiper/>
      <TagLine text={data.wp.startseite.acfThemeSettings.homepageLeistungenHeadline}
      />
      <Services/>
      <KundenSliderComponent/>
      <Contact/>
    </Layout>
  )
}
