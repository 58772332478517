import React from "react"
import { Swiper, SwiperSlide } from 'swiper/react';
import '../styles/swiper.scss';
import { Link } from "gatsby"
import Img from "gatsby-image"
import { Headline } from "./headline"
import styled from "styled-components"
import "../styles/teamSwiper.scss"
import { AllWordpressWpTeamLarge } from "../hooks/allWordpressWpTeamLarge"
import { MoreLink } from "./moreLink"
import { device } from "../utils/devices"

const swiperparams = {
  effect: "coverflow",
  simulateTouch: false,
  centeredSlides: true,
  navigation: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  coverflowEffect: {
    rotate: 0,
    stretch: 1.3,
    depth: 0,
    modifier: 0,
    slideShadows: false,
  },
  spaceBetween: 16,
  slidesPerView: 1.2,
  breakpoints: {
    1024: {
      slidesPerView: 5,
    },
    768: {
      slidesPerView: 2.2,
    },
    640: {
      slidesPerView: 1.7,
    },
  },
}

const StyledSwiperItem = styled.div`
    box-sizing: border-box;
`

const StyledTeamSlogan = styled.div`
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1;
    font-size: 30px;
    margin-left: 16px;
    margin-bottom: 20px;
    @media ${device.tablet} {
      text-align: center;
      margin-left: 0;
      margin-bottom: 0;
      position: absolute;
      left: 0;
      transform: rotate(-90deg) translateY(100%) translateX(-50%);
      transform-origin: bottom left;
      font-size: 60px; 
      z-index: 2;
      bottom: 50%;
      width: auto;
      display: flex;
    }    
    
    @media ${device.desktop} {
      font-size: 100px; 
    }
`

const StyledTeamWrapper = styled.div`
  margin-bottom: 100px;
  margin-top: 100px;
  position: relative;
`

const StyledTeamContent = styled.q`
  font-style: italic;
  font-family: 'Merriweather', Georgia, serif;
  margin-bottom: 40px;
  display: block;
`

export const TeamComponent = () => {
  const allWordpressWpTeamLarge = AllWordpressWpTeamLarge()

  return (
    <StyledTeamWrapper>
      <StyledTeamSlogan>
        Vier gewinnt.
      </StyledTeamSlogan>
      <div className={"member__swiper"}>
        <Swiper {...swiperparams}>
          {allWordpressWpTeamLarge.allWpAcfFotograf.nodes.map((item, index) => {
              const SwiperContent = ({ hasLink }) => (
                <StyledSwiperItem className={"row align-items-center"}>
                  <div className={"col-12 mb-4 mb-lg-0 col-lg-6 member__picture"} css={`filter: url("#${item.acfFotografenDetails.farbe}");`}>
                    {item.featuredImage.node.localFile &&
                    <Img fluid={item.featuredImage.node.localFile.childImageSharp.fluid}/>
                    }
                  </div>
                  <div className={"col-12 col-lg-6 member__content"}>
                    <Headline
                      title={item.title}
                      thinSubline={item.acfFotografenDetails.schwerpunkte}
                    />
                    {item.acfFotografenDetails.kurzbeschreibung &&
                    <StyledTeamContent>{item.acfFotografenDetails.kurzbeschreibung}</StyledTeamContent>
                    }
                    {hasLink &&
                    <MoreLink text={"Profil ansehen"}/>
                    }
                  </div>
                </StyledSwiperItem>
              )

              if (item.acfFotografenDetails.active) {
                return (
                  <SwiperSlide key={index}>
                    <Link to={item.link} style={{ textDecoration: "none" }}>
                      <SwiperContent hasLink={item.acfFotografenDetails.active}/>
                    </Link>
                  </SwiperSlide>
                )
              }

              return (
                <SwiperSlide key={index}>
                  <SwiperContent/>
                </SwiperSlide>
              )
            },
          )
          }
        </Swiper>
      </div>
    </StyledTeamWrapper>
  )
}
